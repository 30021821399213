.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.dark{
  --text-color: var(--black);
  --bg-color: var(--ice);
}

.container {
  --padding-container: var(--spacing-80);
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-8);
  color: var(--text-color);
  padding: var(--padding-container);
}

.subtitle{
  position: relative;
  font: var(--font-p1-desktop-default);
  font-weight: 900;
  margin-left: 3.3rem;
}

.subtitle::before{
  content: "";
  position: absolute;
  top: 50%;
  left: calc(2rem * -1 - var(--spacing-16));
  transform: translateY(-40%);
  width: 2rem;
  height: 8px;
  background: var(--red);
}

.title{
  word-break: initial;
  font-weight: 900;
}

@media (max-width: 1024px) {
  .container{
    --padding-container: var(--spacing-40) var(--spacing-24);
  }
}

@media (max-width: 768px) {
  .subtitle{
    font: var(--font-p1-mobile-default);
    font-weight: 900;
    margin-left: 2.7rem;
  }
  .subtitle::before{
    left: -2.5rem;
  }
}

@media (max-width: 480px) {
  .container{
    --padding-container: var(--spacing-24);
  }
}