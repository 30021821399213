.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.dark{
  --text-color: var(--black);
  --bg-color: var(--ice);
}

.wrapper{
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-100) var(--spacing-80);
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-40);
  background: var(--bg-color);
  color: var(--text-color);
  padding: var(--padding-container);
}

.title {
  padding: 0 !important;
}

.questions > *{
  border-bottom: 1px solid var(--grey);
}

.questions > *:last-child{
  border: none;
}


@media (max-width: 1024px) {
  .container{
    --padding-container: var(--spacing-40) var(--spacing-24);
    gap: var(--spacing-40);
  }
  .title{
    padding: 0 !important;
  }
}

@media (max-width: 480px) {
  .container{
    --padding-container: var(--spacing-40) var(--spacing-24);
  }
}